import React from 'react';

function ProductBox({ addToCart }) {
  const arr = [
    { title: '羊皮紙', value: '1', price: '100' },
    { title: '羽毛筆', value: '2', price: '220' },
    { title: '橡皮擦', value: '3', price: '230' },
    { title: '蠟筆', value: '4', price: '150' },
  ];

  return (
    <div className='product_area_container'>
      <div className='total_info_box'>
        <div>共{ arr.length }件商品</div>
      </div>

      {arr.map((element) => (
        <div key={element.value} className="card product_container">
          <div className='drip_img_container'>
            <img className="card-img-top" src={`/user/15044/${element.value}.jpg`} alt={element.title}></img>
          </div>
          <div className="card-body">
            <h5 className="card-title">{element.title}</h5>
            <p className="card-text">NT$ {element.price}</p>
            <button className="btn btn-primary" onClick={() => addToCart(element)}>
              加入購物車
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductBox;
