import React, { useState } from 'react';
import TopBar from './TopBar';
import DesignPattern from './DesignPattern';
import ProductBox from './ProductBox';
import BottomBar from './BottomBar';
import SmilePayBottomBar from './SmilePayBottomBar';

function App(){
  const [cartItems, setCartItems] = useState([]); // 用來追蹤購物車中的商品

  // 當加入購物車時，更新購物車狀態
  const addToCart = (item) => {
    setCartItems((prevItems) => [...prevItems, item]);
  };

  return(
    <div className='container-fluid drip_container'>
    <TopBar cartItems={cartItems} /> {/* 將 cartItems 傳給 TopBar */}
    <DesignPattern />
    <ProductBox addToCart={addToCart} /> {/* 將 addToCart 函數傳給 ProductBox */}
    <BottomBar />
    <SmilePayBottomBar />
  </div>
  )
}

export default App