import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function TopBar({ cartItems }) {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const divRef = useRef(null);

  const handleCartClick = () => {
    if (isDivVisible) {
      divRef.current.classList.remove('left_slide_out');
      divRef.current.classList.add('left_slide_back');
      setTimeout(() => {
        setIsDivVisible(false);
      }, 300);
    } else {
      setIsDivVisible(true);
    }
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      divRef.current.classList.remove('left_slide_out');
      divRef.current.classList.add('left_slide_back');
      setTimeout(() => {
        setIsDivVisible(false);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderDivInBody = () => {
    if (!isDivVisible) return null;
    return ReactDOM.createPortal(
      <div ref={divRef} className='bg-light cart_list left_slide_out'>
        <h2 className='text-center my-3'>購物車</h2>
        <div className='vh-100'>
          {cartItems.map((item, index) => (
            <div className='border border-5 rounded d-flex flex-row bg-light bg-gradient mb-3 justify-content-evenly align-items-center p-5' key={index}>
                <div>{item.title}</div>
                <div>NT${item.price}</div>
                < div className = "row" >
                <div className = "col-lg-6">
                <div className = "input-group">
                <span className = "input-group-btn">
                <button className = "btn btn-default" type = "button">-</button>
                </span>
                <input type = "text" className = "form-control text-center" placeholder = "1" />
                <span className = "input-group-btn">
                <button className = "btn btn-default" type = "button">+</button>
                </span>
                </div>
                </div>
                </div>
            </div>
          ))}
        </div>
      </div>,
      document.body
    );
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light drip_navbar">
      <a className="navbar-brand drip_logo" href="#">
        <img src="./drip.png" alt="Logo" />
        Drip
      </a>
      <div className="collapse navbar-collapse drip_top_bar" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">關於</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">購買說明</a>
          </li>
        </ul>
      </div>

      <div className='drip_cart_container'>
        <div className="btn btn-cart drip_cart" onClick={handleCartClick}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M3.03998 2.29242C2.64921 2.15503 2.22106 2.36044 2.08368 2.7512C1.94629 3.14197 2.1517 3.57012 2.54246 3.7075L2.80367 3.79934C3.47128 4.03406 3.91003 4.18961 4.23288 4.34802C4.5361 4.4968 4.66977 4.61723 4.75783 4.74609C4.84809 4.87818 4.91779 5.0596 4.95712 5.42295C4.99828 5.80316 4.9993 6.29837 4.9993 7.03832L4.9993 9.64C4.9993 12.5816 5.06254 13.5523 5.92894 14.4662C6.79534 15.38 8.18979 15.38 10.9787 15.38H16.2816C17.8426 15.38 18.6231 15.38 19.1748 14.9304C19.7266 14.4808 19.8841 13.7164 20.1992 12.1875L20.699 9.76275C21.0461 8.02369 21.2197 7.15417 20.7757 6.57708C20.3318 6 18.815 6 17.1301 6H6.49184C6.48515 5.72967 6.47247 5.48373 6.44841 5.26153C6.39468 4.76515 6.27827 4.31243 5.99629 3.89979C5.71211 3.48393 5.33426 3.21759 4.89363 3.00139C4.48154 2.79919 3.95791 2.61511 3.34138 2.39838L3.03998 2.29242Z" fill="#1C274C"/>
                <path d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z" fill="#1C274C"/>
                <path d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z" fill="#1C274C"/>
                <path d="M9.37387 8.26066C9.78227 8.19147 10.1694 8.46645 10.2386 8.87485L10.7668 11.9927C10.836 12.4011 10.561 12.7883 10.1526 12.8574C9.74421 12.9266 9.35705 12.6516 9.28787 12.2432L8.75968 9.12539C8.69049 8.717 8.96548 8.32984 9.37387 8.26066Z" fill="#1C274C"/>
                <path d="M14.788 8.87485C14.8572 8.46645 15.2443 8.19147 15.6527 8.26066C16.0611 8.32984 16.3361 8.717 16.2669 9.12539L15.7387 12.2432C15.6696 12.6516 15.2824 12.9266 14.874 12.8574C14.4656 12.7883 14.1906 12.4011 14.2598 11.9927L14.788 8.87485Z" fill="#1C274C"/>
            </svg>
          <span className="badge-pill text-white bg-danger rounded-circle drip_cart_pill">{cartItems.length}</span>
        </div>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>

      {renderDivInBody()}
    </nav>
  );
}

export default TopBar;
